import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as notificationActions from "../notifications/actions/notificationActions";
import * as importActions from "./actions/importActions";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import UploadButton from "../../components/common/UploadButton";
import ErrorLabel from "../../components/ErrorLabel";
import PropTypes from "prop-types";

class ImportModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      isWorking: false,
      isLoading: false,
      errors: {},
      uploadFile: {},
      fileUploaded: false,
    };
  };

  handleClose = () => {
    this.props.importActions.hideImportModal();
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.import.showImportModal &&
      !this.props.import.showImportModal
    ) {
      //need customers reload
      this.reloadCustomers();
      this.setState(this.getInitialState());
    }
    if (
      !this.state.selectedImporter &&
      nextProps.import.importers &&
      nextProps.import.importers.data.length > 0
    ) {
      let firstImporter = nextProps.import.importers.data[0];
      this.setState({
        selectedImporter: {
          value: firstImporter.id,
          label: firstImporter.name,
        },
      });
    }
  }

  reloadCustomers = () => {
    this.setState({ isLoading: true });
    this.props.importActions
      .listCustomers({
        pageSize: null,
        page: null,
        sorting: [
          {
            id: "name",
            desc: false,
          },
        ],
      })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  componentDidMount() {
    this.reloadCustomers();
  }

  handleCustomerChange = (item) => {
    this.setState({ selectedCustomer: item });
    this.setState({ isLoading: true });
    this.props.importActions
      .listImporters({ customerId: item.value, pageSize: null })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  handleImporterChange = (item) => {
    this.setState({ selectedImporter: item });
  };

  handleSave = (e) => {
    e.preventDefault();
    this.validate(() => {
      this.setState({ isWorking: true });
      this.props.importActions
        .addFileToQueue({
          importerId: this.state.selectedImporter.value,
          uploadFile: this.state.uploadFile,
        })
        .then((response) => {
          this.setState({ isWorking: false, fileUploaded: true });
          this.props.notification.add("File added to queue", "", "success");
        })
        .catch((error) => {
          this.props.notification.add("An error has occurred", "", "fail");
        });
    });
  };

  validate = (success) => {
    let errors = {};

    if (!this.state.uploadFile.name) errors["fileUpload"] = "File not selected";
    if (!this.state.selectedImporter)
      errors["selectedImporter"] = "Importer not selected";
    this.setState(
      {
        errors: errors,
      },
      () => {
        let hasErrors = Object.keys(this.state.errors).length !== 0;
        if (!hasErrors) success();
      },
    );
  };

  getError = (key) => {
    return this.state.errors[key];
  };

  render() {
    return (
      <Modal show={this.props.import.showImportModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Customer</b>
          <Select
            isLoading={this.state.isLoading}
            value={this.state.selectedCustomer}
            onChange={(item) => {
              this.handleCustomerChange(item);
            }}
            options={
              this.props.import.customers
                ? this.props.import.customers.data.map((i) => {
                    return { value: i.id, label: i.name };
                  })
                : []
            }
          />
          <div className="margin-top-15">
            <b>Importer</b>
            <Select
              isLoading={this.state.isLoading}
              value={this.state.selectedImporter}
              onChange={(item) => {
                this.handleImporterChange(item);
              }}
              options={
                this.props.import.importers
                  ? this.props.import.importers.data.map((i) => {
                      return { value: i.id, label: i.name };
                    })
                  : []
              }
            />
            <ErrorLabel error={this.getError("selectedImporter")} />
          </div>
          <div className="margin-top-15">
            <b>File</b>
            <div>
              <UploadButton
                onFileChanged={(files) => {
                  this.setState({ uploadFile: files[0] });
                }}
                btnClass={"btn btn-xs btn-success"}
              />
              {this.state.uploadFile ? (
                <span>{this.state.uploadFile.name}</span>
              ) : null}
            </div>
            <ErrorLabel error={this.getError("fileUpload")} />
          </div>
          {this.state.fileUploaded ? (
            <div className="alert alert-success margin-top-10">
              <strong>Success!</strong> File added to queue
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Close</Button>
          <button
            disabled={this.state.isWorking || this.state.fileUploaded}
            onClick={this.handleSave}
            className="btn bg-blue has-text"
          >
            {this.state.isWorking ? (
              <i className="fa fa-spinner fa-spin fa-fw"></i>
            ) : (
              <i className="fa fa-check"></i>
            )}
            <span>Import</span>
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { import: state.import };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    importActions: bindActionCreators(importActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);

ImportModal.propTypes = {
  import: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  importActions: PropTypes.object.isRequired,
};
